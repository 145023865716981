import cls from 'classnames'

export const classes = 'z-20 hover:cursor-pointer bg-green px-12 py-4 text-black font-extrabold w-full uppercase hover:opacity-80 disabled:opacity-60'
export const PrimaryButton = ({text, children, as='button', className, ...props}: { text: string , as?: string, className?: string, props?:any}) => {
    const Element = as
    return (
        <Element className={cls(classes, className)} {...props}>
            {text}
            {children}
        </Element>
    );
}
