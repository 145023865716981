import React, {InputHTMLAttributes, useCallback, useEffect, useRef} from 'react';
import {useScript} from 'usehooks-ts';
import {TextField} from './TextField';

const UseScriptLoader = ({apiKey, onLoaded}: {
  apiKey: string,
  onLoaded: () => void
}) => {
  const scriptState = useScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`, {
    removeOnUnmount: true
  });

  useEffect(() => {
    if (scriptState === 'ready') {
      onLoaded();
    }
  }, [scriptState, onLoaded]);

  return null;
};

const DelegateScriptLoader = ({onLoaded}: {
  onLoaded: () => void
}) => {
  useEffect(() => {
    let interval: NodeJS.Timeout;
    let cancelled = false;

    interval = setInterval(() => {
      if (window.google?.maps?.places?.Autocomplete !== undefined) {
        clearInterval(interval);
        if (!cancelled) {
          onLoaded();
        }
      }
    }, 100);

    return () => {
      cancelled = true;
      clearInterval(interval);
    };
  }, [onLoaded]);

  return null;
};

export const CityOrPostcodeField = ({apiKey, onGeoCode, ...props}: InputHTMLAttributes<HTMLInputElement> & {
  apiKey?: string;
  dealerTextField?: boolean;
  invertColor?: boolean,
  onGeoCode: ({lat, lng}: {lat: number, lng: number} | {lat: null, lng: null}) => void,
}) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const initAutocomplete = useCallback(() => {
    inputRef.current!.addEventListener('blur', (e) => {
      if((e.target as HTMLInputElement).value === '') {
        onGeoCode({lat: null, lng: null});
      }
    });

    autoCompleteRef.current = new google.maps.places.Autocomplete(inputRef.current as HTMLInputElement, {
      componentRestrictions: {country: 'nz'},
      fields: ['geometry.location'],
      types: ['(regions)']
    });

    autoCompleteRef.current.addListener('place_changed', () => {
      const place = autoCompleteRef.current!.getPlace();
      onGeoCode({lat: place.geometry!.location!.lat(), lng: place.geometry!.location!.lng()});
    });
  }, []);

  return <>
    {apiKey
      ? <UseScriptLoader apiKey={apiKey} onLoaded={initAutocomplete}/>
      : <DelegateScriptLoader onLoaded={initAutocomplete}/>
    }
    <TextField ref={inputRef} {...props} />
  </>;
};

