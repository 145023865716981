export type Product = {
  code: string;
  name: string;
  dealerPrice: number;
  stockLabel: [string, 'info' | 'success' | 'warning' | 'error'];
  available: number;
  thumbnailImage: string | null;
};

export type ProductsPaginationPage = {
  currentBool: boolean;
  link: string | null;
  pageNumber: number | null;
};

export type ProductsPagination = {
  moreThanOnePage: boolean;
  nextLink: string | null;
  notFirstPage: boolean;
  notLastPage: boolean;
  paginationPages: ProductsPaginationPage[];
  prevLink: string | null;
};

export type ProductsResponse = {
  data: Product[];
  pagination: ProductsPagination;
};

export type CartItem = {
  code: string;
  title: string;
  quantity: number;
  price: number;
}

type Cart = {
  id: number;
  items: CartItem[];
  reference?: string;
  notes?: string;
};

export type StockIssue = {
  code: string;
  issue: 'low' | 'out';
  available: number;
};

export type RequestWithAbortController<T> = {request: Promise<T>, abortController: AbortController};

/* {[search term]: {[page number]: ProductsResponse}} */
const productPages: Record<string, Record<number, ProductsResponse>> = {};

export const getProducts = (page: number, perPage = 20, searchTerm: string = ''): {request: Promise<ProductsResponse>, abortController?: AbortController} => {
  if (page in (productPages[searchTerm] ?? {})) {
    return {
      request: Promise.resolve(productPages[searchTerm][page])
    };
  }

  const abortController = new AbortController();
  const signal = abortController.signal;

  const request = fetch(`/dealers/api/products?start=${page * perPage}&perPage=${perPage}&search=${searchTerm}`, {signal})
    .then((r): Promise<ProductsResponse> => r.json())
    .then(r => {
      productPages[searchTerm] = productPages[searchTerm] || {};
      productPages[searchTerm][page] = r;
      return r;
    });

  return {request, abortController};
};

export const getCart = (): RequestWithAbortController<Cart> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const request = fetch('/dealers/api/cart', {signal})
    .then((r): Promise<Cart> => r.json());

  return {request, abortController};
};

export const saveCart = (cart: Omit<Cart, 'id'>): RequestWithAbortController<Cart> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const body = new FormData();
  body.append('reference', cart.reference ?? '');
  body.append('notes', cart.notes ?? '');
  cart.items.map((item, index) => {
    body.append(`items[${index}][code]`, item.code);
    body.append(`items[${index}][quantity]`, item.quantity.toString());
  });

  const request = fetch('/dealers/api/saveOrder', {
    method: 'POST',
    signal,
    body,
  }).then(r => r.json());
  return {request, abortController};
}

export const checkStock = (): RequestWithAbortController<{issues: StockIssue[]}> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const request = fetch('/dealers/api/stockCheck', {
    method: 'POST',
    signal,
  }).then(r => r.json());
  return {request, abortController};
};

export const submitCart = (): RequestWithAbortController<{success: true, redirect: string}> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const request = fetch('/dealers/api/submitOrder', {
    method: 'POST',
    signal,
  }).then(r => r.json());
  return {request, abortController};
};