import {Option} from '../components/FormikFields/SelectField';
import {RequestWithAbortController} from './dealerCart';

export type GetPriceTypesResponse = {
  allPriceTypes: Option[],
  currentPriceType: string,
  isDefaultPriceType: boolean,
}

export const getPriceCodes = (): RequestWithAbortController<GetPriceTypesResponse> => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const request = fetch('/dealers/priceTypes', {signal})
    .then((r): Promise<GetPriceTypesResponse> => r.json());

  return {request, abortController};
};