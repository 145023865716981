import {useFormikContext} from "formik";
import React, {ChangeEvent} from "react";
import {TextAreaField as CustomTextAreaField} from "../FormFields/TextAreaField";


export const TextAreaField = ({title, name, ...props}: { title: string; name: string; }) => {
    const {setFieldValue} = useFormikContext<any>();
    return (
        <CustomTextAreaField title={title}
                         onChange={(e: ChangeEvent<HTMLSelectElement>) => setFieldValue(name, e.target.value)} {...props}/>
    )
}
