import {useFormikContext} from "formik";
import React, {ChangeEvent, useEffect, useState} from 'react';
import {SelectField as CustomSelectField} from "../FormFields/SelectField";

export type Option = { value: any, label: string }

export const SelectField = ({
                                title,
                                name,
                                options,
                                ...props
                            }: { title: string; name: string; options: Option[] }) => {

    const {values, setFieldValue, errors, isSubmitting} = useFormikContext<any>();
    const [hasSubmitted, setHasSubmitted] = useState(false)
    useEffect(() => {
        if(isSubmitting){
            setHasSubmitted(isSubmitting)
        }
    },[isSubmitting])

    return (
        <>
        <CustomSelectField value={values[name]} title={title}
                           onChange={(e: ChangeEvent<HTMLSelectElement>) => setFieldValue(name, e.target.value)}
                           options={options}
                           {...props}
                           clear={() => setFieldValue(name, '')}/>
            {errors[name] && hasSubmitted ? <p className='text-red-450'>{errors[name]}</p> : null}
        </>
    )
}
