import React, {ChangeEvent} from "react";

export const TextAreaField = ({
                              title,
                              onChange,
                              invertColor = false,
                              dealerTextField = false,
                              ...props
                          }: { title: string; dealerTextField?: boolean, onChange: (e: ChangeEvent<HTMLSelectElement>) => void; invertColor?: boolean }) => {
    return (
        <div className={"w-full border-b border-gray-600 z-10" + (invertColor ? " hover:border-black" : 'hover:border-white')}>
            <textarea
                className={"w-full outline-0 border-0 py-4 placeholder-gray-500"  + (invertColor ? " bg-white hover:placeholder-black text-black" : " bg-black text-white hover:placeholder-white")}
                placeholder={title}
                //@ts-ignore the typing above is not quiet correct tp be used in this input but its a good enough type for the use case of this component
                onChange={onChange}
                {...props}
            />
        </div>
    )
}
