'use strict';
import cls from 'classnames';
import React, {MouseEventHandler, useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PageWidthConstraint from './PageWidthConstraint';
import {useSpring, animated} from '@react-spring/web';

interface Slide {
    title: string | undefined;
    caption: string;
    alt: string;
    src: string;
    button: {
        title: string;
        url: string;
        anchor: string
        openInNew: boolean;
    }  | undefined;
}

interface SliderProps {
    slides: Slide[];
}

interface SlickArrow {
    onClick: MouseEventHandler<HTMLButtonElement> | undefined,
    currentSlide: number
}

export function ItemSlider({slides}: SliderProps) {
    const sliderRef = useRef<Slider | null>(null);
    const indexBar = useRef(null);
    const [positions, setPositions] = useState<number[]>([]);
    const ArrowLeft = () => (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.666484 11.1667L0.666484 8.83333L14.6665 8.83332L8.24982 2.41666L9.90648 0.759991L19.1465 9.99999L9.90648 19.24L8.24982 17.5833L14.6665 11.1667L0.666484 11.1667Z" fill="#18191B"/>
        </svg>
    )

    const NextArrow = ({onClick, currentSlide}: SlickArrow) => (
        <button className="z-30 bg-green p-5 absolute right-5 bottom-10 disabled:opacity-50 lg:block hidden" disabled={currentSlide === slides.length - 1} onClick={onClick}>
            <ArrowLeft/>
        </button>
    );

    const PrevArrow = ({onClick, currentSlide}: SlickArrow) => (
        <button className="z-30 bg-green p-5 absolute right-20 bottom-10 disabled:opacity-50 lg:block hidden" disabled={currentSlide === 0 } onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3335 8.83334V11.1667H5.33352L11.7502 17.5833L10.0935 19.24L0.853516 10L10.0935 0.76001L11.7502 2.41668L5.33352 8.83334H19.3335Z" fill="#18191B"/>
            </svg>
        </button>
    );

    const [springs, api] = useSpring(() => ({
        from: {x: 0},
    }));

    function factorialOfStep(step: number, amount: number) {
        let stepArray = [];
        for (let i = 1; i <= amount; i++) {
            stepArray.push(step * i);
        }
        return stepArray;
    }

    useEffect(() => {
        //@ts-ignore
        const fullIndexBar = indexBar.current?.offsetWidth;
        const step = fullIndexBar / Math.ceil(slides.length/4);
        setPositions([0, ...factorialOfStep(step, slides.length - 1)]);
    }, [indexBar.current]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        autoplaySpeed: 5000,
        dotsClass: 'w-1/3',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
        appendDots: (dots: any) => {
            const index = dots.findIndex((dot: any) => dot.props.className === 'slick-active');
            api.start({
                from: {
                    x: springs.x.animation.to,
                },
                to: {
                    x: positions[index],
                },
            });
            return (
                <div>
                    <PageWidthConstraint className='relative'>
                        <animated.div style={{
                            //@ts-ignore
                            width: indexBar.current?.offsetWidth / dots.length + 'px',
                            ...springs,
                        }} className="border-b-2 border-green -mb-px z-20"/>
                        <div ref={indexBar} className="border-b-2 z-10 border-black opacity-10 w-full"/>
                    </PageWidthConstraint>
                </div>);
        },
        //@ts-ignore
        nextArrow: <NextArrow/>,
        //@ts-ignore
        prevArrow: <PrevArrow/>,
        slidesToShow: 4,
        slidesToScroll: 4,
    };

    const itemClasses = "h-auto sm:w-full max-h-64 2xl:w-full object-cover"
    return (
        <div className="relative -mb-1.5">
            <Slider ref={slider => (sliderRef.current = slider)} {...settings} className="w-full relative h-auto px-3">
                {slides.map((item, index) => (
                    <article key={index} className={cls('relative p-3', slides.length <= 4 && 'mb-10')}>
                        <div className='h-64 mb-4'>
                            <img className={itemClasses} src={item.src} alt={item.alt}/>
                        </div>
                        <h4 className='text-2xl uppercase font-semibold py-5'>{item.title}</h4>
                        <div className="content w-11/12" dangerouslySetInnerHTML={{__html: item.caption}}/>
                        <a href={item.button?.url} className='text-xl my-3 flex space-x-3 align-center'><span>{item.button?.title}</span> <span className='mt-1'><ArrowLeft/></span></a>
                    </article>
                ))
                }
            </Slider>
        </div>
    );
}
