'use strict';
import React, {useEffect, useState} from 'react';

const toTitleCase = (text: string) => {
    return text
        .replace(/-|\s/g, " ")
        .toLowerCase()
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};



export function BreadCrumbs({ignore, endText}: {ignore?: string[], endText?: string}) {

    const [routeNames, setRouteNames] = useState([]);
    useEffect(() => {
        setRouteNames(window.location.pathname.split('/').filter(n => n).filter(item => !ignore?.includes(item)))
    },[window.location.pathname])

    if(routeNames.length > 1){
        return (
            <div className="text-xs flex flex-wrap">
                {routeNames.map((route, index) => {
                    if (index === routeNames.length - 1) {
                        return (
                            <p className="font-normal" key={index}>
                                {endText ? endText : toTitleCase(route)}
                            </p>
                        )
                    }
                    return (
                        <div className='flex' key={index}>
                            <a href={window.location.pathname.split(route)[0] + route} className='font-light'>
                                {toTitleCase(route)}
                            </a>
                            <p className="text-lg text-green -mt-2 px-4">/</p>
                        </div>
                    );
                })}
            </div>
        );
    }
    return null
}

