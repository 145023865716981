'use strict';
import {XMarkIcon} from '@heroicons/react/20/solid';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {Dialog, DialogClose, DialogContent, DialogTrigger} from './Dialog';


export function ImageDialog({children, imageUrl}: { children: React.ReactNode, imageUrl: string }) {
  return (
    <Dialog>
      <DialogTrigger className="cursor-zoom-in" asChild>
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogClose className="absolute -right-2.5 -top-2.5 bg-white rounded-full shadow-2xl p-2">
          <XMarkIcon className="text-black h-10"/>
        </DialogClose>
        <img src={imageUrl} className="2xl:max-w-screen-xl 2xl:max-h-9/10" alt="zoomed in image"/>
      </DialogContent>
    </Dialog>
  );
}
