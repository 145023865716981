import cls from 'classnames';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {fitMy4X4FormikProps, getMakes, getModels, getSeries, Make, Model, Series} from './FitMy4x4';
import {SelectField} from './FormikFields/SelectField';
import {PrimaryButton} from './PrimaryButton';
import {classes, SecondaryButton} from './SecondaryButton';

export const FitMy4x4Filter = ({darkTheme = false, href, title = "Filter by 4x4", className}: { darkTheme: boolean, href: string, title?: string, className?: string }) => {
    const [allMakes, setAllMakes] = useState<Make[] | []>([]);
    const [allModels, setAllModels] = useState<Model[] | []>([]);
    const [allSeries, setAllSeries] = useState<Series[] | []>([]);
    const defaultRoute = '/fit-my-4x4'
    const redirect = href !==  defaultRoute

    useEffect(() => {
        getMakes(setAllMakes);
    }, []);

    return (
        <div className={cls('px-8', className)}>
            {title && <h3 className="text-xl uppercase font-bold">{title}</h3>}
            <Formik initialValues={fitMy4X4FormikProps(redirect).initialValues} onSubmit={fitMy4X4FormikProps(redirect).onSubmit}>
                {({handleSubmit, values}) => {
                    useEffect(() => {
                        getModels(values.make, setAllModels);
                    }, [values.make]);

                    useEffect(() => {
                        getSeries(values.make, values.model, setAllSeries);
                    }, [values.make, values.model]);
                    return (
                        <form className="lg:space-y-6 lg:mt-6 lg:mb-10 my-2" onSubmit={handleSubmit}>
                            <SelectField title="Make" name="make" options={allMakes.map(make => ({
                                value: make.ID,
                                label: make.Name,
                            }))} invertColor={!darkTheme}/>
                            <SelectField title="Model" name="model" options={allModels.map(model => ({
                                value: model.ID,
                                label: model.Name,
                            }))} invertColor={!darkTheme}/>
                            <SelectField title="Series"  name="series" options={allSeries.map(series => ({
                                value: series.ID,
                                label: series.Name,
                            }))} invertColor={!darkTheme}/>

                            <div className='xl:flex xl:space-x-4 lg:mt-0 mt-4'>
                                <a href={defaultRoute + '?clear=1' + ( redirect ? '&redirect-back=1' : '')} className="z-20 hover:cursor-pointer px-8 py-4 font-extrabold w-full uppercase hover:opacity-80 xl:w-6 relative">
                                        <span className='xl:hidden block px-12 py-4 text-black bg-green font-extrabold text-center w-full uppercase'>Clear</span>
                                        <svg width="18" height="18" className='-ml-2 mt-1 fill-black xl:block hidden' viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.1666 2.47825L15.5216 0.833252L8.99992 7.35492L2.47825 0.833252L0.833252 2.47825L7.35492 8.99992L0.833252 15.5216L2.47825 17.1666L8.99992 10.6449L15.5216 17.1666L17.1666 15.5216L10.6449 8.99992L17.1666 2.47825Z"/>
                                        </svg>
                                </a>
                                {/*, darkTheme ? '' : 'bg-green text-black')*/}
                                <SecondaryButton className={cls(classes, 'block text-center')} type="submit">
                                    Refine
                                </SecondaryButton>
                            </div>
                        </form>
                    );
                }}
            </Formik>

        </div>
    );
};

