import React, {ChangeEvent} from "react";

export const CheckboxField = ({
                                  title,
                                  onChange,
                                  invertColor = false,
                                  ...props
                              }: { title: string; onChange: (e: ChangeEvent<HTMLSelectElement>) => void; invertColor?: boolean }) => {
    return (
        <div className="flex gap-4">
            <input
                type='checkbox'
                className="w-8 accent-green"
                //@ts-ignore the typing above is not quiet correct tp be used in this input but its a good enough type for the use case of this component
                onChange={onChange}
                {...props}
            />
            <span>
                {title}
            </span>
        </div>
    )
}
