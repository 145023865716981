type Location = {
  lat: number;
  lng: number;
};

const toRadian = (degree: number) => {
  return degree * Math.PI / 180;
};

// Returns the distance in KM between the two locations.
export const haversineDistance = (loc1: Location, loc2: Location) => {
  const R = 6371; // radius of Earth in km
  const dLat = toRadian(loc2.lat - loc1.lat);
  const dLng = toRadian(loc2.lng - loc1.lng);

  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(toRadian(loc1.lat)) * Math.cos(toRadian(loc2.lat)) *
    Math.sin(dLng/2) * Math.sin(dLng/2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
}