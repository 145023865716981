import React, {ChangeEvent} from "react";

export const RadioGroupField = ({
                                    onChange,
                                    options,
                                    invertColor = false,
                                    name = 'radio-field',
                                    containerClassName = ''
                                }: { onChange: (e: ChangeEvent<HTMLSelectElement>) => void, options: { value: string, label: string }[], invertColor?: boolean, name?: string, containerClassName?: string }) => {
    return (
        <div className={containerClassName}>
            {options.map((option: any) => (
                <div key={option.value} className="flex gap-4 items-center">
                    {/*@ts-ignore*/}
                    <input type="radio" onChange={onChange} value={option.value} name={name}
                           className="w-4 h-4 border-white accent-green bg-green"/>
                    <span>{option.label}</span>
                </div>
            ))}

        </div>
    )
}
