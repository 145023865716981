import axios from 'axios';
import cls from 'classnames';
import {TextField} from './FormFields/TextField';
import {SelectField} from './FormFields/SelectField';
import {getStoreTypes, getServices} from './FindAStore';
import {ChangeEvent, useEffect, useState} from 'react';
import {classes} from './PrimaryButton';

export const FindAStoreContentBlock = ({darkTheme = true, href = '/find-a-store'}: { darkTheme: boolean, href: string }) => {
    const [store, setStore] = useState('');
    const [address, setAddress] = useState('');
    const [service, setService] = useState('');
    const [services, setServices] = useState([])
    const [storeTypes, setStoreTypes] = useState([])

    useEffect(() => {
        getServices(setServices);
        getStoreTypes(setStoreTypes);
    }, []);

    return (
        <>
            <div className="lg:space-y-6 lg:mt-6 lg:mb-10 my-2">
                <TextField title="City or Postcode"
                           onChange={(e: ChangeEvent<HTMLSelectElement>) => setAddress(e.target.value)}
                           invertColor={!darkTheme}/>
                <SelectField title="Store Type" value={store} clear={() => setStore('')}
                             onChange={(e: ChangeEvent<HTMLSelectElement>) => setStore(e.target.value)}
                             options={storeTypes} invertColor={!darkTheme}/>
                <SelectField title="Service" value={service} clear={() => setService('')}
                             onChange={(e: ChangeEvent<HTMLSelectElement>) => setService(e.target.value)}
                             options={services} invertColor={!darkTheme}/>

            </div>

            <a href={href + `?store=${store}&address=${address}&service=${service}`} className={cls(classes, 'block text-center')}>
                Search
            </a>
        </>
    );
};
