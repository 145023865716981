import {useFormikContext} from "formik";
import React, {ChangeEvent} from "react";
import {CheckboxField as CustomCheckboxField} from "../FormFields/CheckboxField";


export const CheckboxField = ({title, name, ...props}: { title: string; name: string; }) => {
    const {setFieldValue} = useFormikContext<any>();
    return (
        <CustomCheckboxField title={title}
                         onChange={(e: ChangeEvent<HTMLSelectElement>) => setFieldValue(name, e.target.value)} {...props}/>
    )
}
