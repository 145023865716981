import {mdiEmailOutline, mdiFacebook, mdiWeb} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';

export const StoreList = ({
    hovering,
    value,
    hasStoreList = true,
}: { hovering: string, value: any, hasStoreList?: boolean }) => (
    <div
        className={cls('flex flex-col gap-2 px-4 py-8 border-b border-gray-300 lg:w-125 w-full', hovering === value.address ? 'bg-gray-200' : ' bg-white')}>
        <div className="text-2xl">{value.name}</div>
        <div className="text-gray-500 text-xs">{value.description}</div>
        <div className="text-sm">{value.address}</div>
        {
            hasStoreList && <div className="flex flex-row justify-between text-sm">
                {value.website ? <a href={value.website} target="_blank"
                                    className="flex items-center gap-2">
                    <Icon path={mdiWeb} className="h-5 text-green"/>
                    Website
                </a> : null}
                {value.email ? <a href={'mailto:' + value.email} target="_blank"
                                  className="flex items-center gap-2">
                    <Icon path={mdiEmailOutline} className="h-5 text-green"/>
                    Email
                </a> : null}
                {value.facebook ? <a href={value.facebook} target="_blank"
                                     className="flex items-center gap-2">
                    <Icon path={mdiFacebook} className="h-5 text-green"/>
                    Facebook
                </a> : null}
            </div>
        }
    </div>
);

