import {CogIcon} from '@heroicons/react/20/solid';
import {ArrowDownTrayIcon} from '@heroicons/react/24/solid';
import axios from 'axios';
import cls from 'classnames';
import React, {useEffect, useState} from 'react';
import {TextField} from './FormFields/TextField';
import {ImageDialog} from './ImageDialog';

interface Resource {
  mediaURL: string;
  name: string;
  newWindow: boolean;
  productCodes: Record<string, string>;
  thumbnailImage: string;
  image: string;
  bigImage: string;
}

const PaginationButton = ({pageNumber, type, link, generateTable}) => {
  let baseUrl = 'https://example.com';
  let dummyUrl = new URL(link, baseUrl);
  const start = new URLSearchParams(dummyUrl.search).get('start');
  const search = new URLSearchParams(dummyUrl.search).get('search') ?? '';

  let content;
  switch (type) {
    case 'next':
      content = '\u2192';
      break;
    case 'prev':
      content = '\u2190';
      break;
    default:
      content = pageNumber;
  }
  return (
    <button onClick={() =>
      generateTable(search, start)}>
      {content}
    </button>
  );
};

export const ResourcesTable = () => {

  const [tableResources, setTableResources] = useState([]);
  const [tablePagination, setTablePagination] = useState(null);
  const [tableLoad, setTableLoad] = useState(false);

  useEffect(() => {
    generateTable('');
  }, []);

  const generateTable = (inputValue: string, start = 0) => {
    setTableLoad(true);
    axios.get(`/dealers/fetchDocuments?search=${inputValue}&start=${start}`)
      .then(function (response) {
        setTablePagination(response.data.pagination);
        setTableResources(response.data.data);
        setTableLoad(false);
      });
  };

  if (!tablePagination) {
    //todo spinner or loading screen
    return null;
  }
  return (
    <div className="p-6 flex flex-col bg-white my-8 rounded-md">
      <div className="flex w-full items-center justify-between">
        <h2 className="text-xl font-normal">Documents / Resources</h2>
        <div className="flex space-x-4 mb-4">
          <TextField title="Search" autoFocus dealerTextField={true} onChange={(e) => {
            generateTable(e.target.value, 0);
          }} invertColor/>
        </div>

      </div>
      <div className="relative">
        <table className="mt-3 w-full">
          <tr>
            <th className="uppercase text-slate-450 text-left mb-6 text-xs font-light leading-none tracking-widest ">
              Name
            </th>
            <th className="uppercase text-slate-450 text-left mb-6 text-xs font-light leading-none tracking-widest ">
              Product Codes
            </th>
            <th>
            </th>
          </tr>
          <tbody className="divide-y divide-gray-200">
          {tableResources?.map((resource: Resource) => {
            const productCodes = Object.keys(resource.productCodes);
            return (
              <tr>
                <td className="font-light flex space-x-4 py-2 items-center">
                  {resource.thumbnailImage && <ImageDialog imageUrl={resource.image}>
                    <div className="w-10 h-10 rounded bg-no-repeat bg-white bg-center bg-contain"
                         style={{backgroundImage: 'url(' + resource.thumbnailImage + ')'}}>
                    </div>
                  </ImageDialog>}
                  <span>{resource.name}</span></td>
                <td className=" text-slate-450 ">{productCodes.join(', ')}</td>
                <td className=" text-slate-450 ">
                  <a href={resource.mediaURL} target={resource.newWindow ? '_blank' : '_self'} download={!resource.newWindow}
                     className={cls('bg-green py-2 px-4 rounded-lg inline-block', resource.mediaURL ? 'opacity-100' : 'opacity-40')}>
                    <ArrowDownTrayIcon className="h-6 text-black-dealers mx-0"/>
                  </a>
                </td>


              </tr>);
          })
          }
          </tbody>
        </table>
        {tableLoad && <>
          <div className="absolute top-0 w-full h-full bg-white opacity-60 z-10"/>
          <CogIcon className="absolute left-0 right-0 mx-auto my-auto top-0 bottom-0 h-24 w-24 animate-spin z-30" style={{animationDuration: '3s'}}/>
        </>}
      </div>
      {
        Boolean(tablePagination.moreThanOnePage) && (
          <div className="flex space-x-6 justify-center w-full mt-10">
            {Boolean(tablePagination.notFirstPage) && (
              <PaginationButton pageNumber={null} type="prev" link={tablePagination.prevLink} generateTable={generateTable}/>
            )}
            {tablePagination.paginationPages.map((paginationPage: any) =>
              <>
                {Boolean(paginationPage.currentBool) ? (
                    <span className="underline">{paginationPage.pageNumber}</span>
                  ) :
                  Boolean(paginationPage.link) ? (
                    <PaginationButton pageNumber={paginationPage.pageNumber} type="" link={paginationPage.link} generateTable={generateTable}/>
                  ) : <span>...</span>}
              </>,
            )}
            {Boolean(tablePagination.notLastPage) && (
              <PaginationButton pageNumber={null} type="next" link={tablePagination.nextLink} generateTable={generateTable}/>
            )}
          </div>
        )
      }
    </div>
  );
};
