import axios from 'axios';
import cls from 'classnames';
import {useFormikContext} from 'formik';
import React, {ChangeEvent, ReactNode, useEffect, useState} from 'react';
import {haversineDistance} from '../services/haversine';
import {CityOrPostcodeField} from './FormFields/CityOrPostcodeField';
import {SelectField} from './FormFields/SelectField';
import PageWidthConstraint from './PageWidthConstraint';
import {StoreList} from './StoreList';
import {StoresMap} from './StoresMap';


function setEmptyLabel(data) {
  return data.map((row) => {
    if (row.label === '') {
      row.label = '-- Please select --';
    }
    return row;
  });
}

export function getStoreTypes(setStoreTypes) {
  axios.get('/api/dealers/storeTypeOptions')
    .then(function (response) {
      setStoreTypes(setEmptyLabel(response.data));
    });
}

export function getServices(setServices) {
  axios.get('/api/dealers/serviceOptions')
    .then(function (response) {
      setServices(setEmptyLabel(response.data));
    });
}

const distanceOptions = [
  {value: 10, label: '10KM'},
  {value: 20, label: '20KM'},
  {value: 50, label: '50KM'},
  {value: 100, label: '100KM'},
];

const FilterWrapper = ({hasStoreList, children, className}: {
  hasStoreList: boolean,
  children: ReactNode,
  className: string
}) => (
  hasStoreList ? <PageWidthConstraint className={className}>{children}</PageWidthConstraint> : <div className={className}>{children}</div>
);

export const FindAStore = ({
  apiKey,
  hasStoreList = true,
  darkHeader = true,
  name = null,
  setSelectDealer,
  dealerID
}: {
  apiKey: string,
  hasStoreList?: boolean,
  setSelectDealer?: () => {},
  dealerID?: number,
  darkHeader: boolean,
  name?: string | null
}) => {
  const [stores, setStores] = useState([]);
  const [displayStores, setDisplayStores] = useState([]);
  const [hovering, setHovering] = useState('');
  const [clicked, setClicked] = useState('');
  const [store, setStore] = useState('');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState<{
    lat: number,
    lng: number
  } | null>(null);
  const [service, setService] = useState('');
  const [services, setServices] = useState([]);
  const [storeTypes, setStoreTypes] = useState([]);
  const [distance, setDistance] = useState(20);


  useEffect(() => {
    axios.get('/api/dealers')
      .then(function (response) {
        setStores(response.data);
        setDisplayStores(response.data);
        if (dealerID) {
          setSelectDealer(response.data.find(item => item.id === dealerID));
        }
        window.location.search.split('&').forEach(param => {
          const keyValueArray = param.split('=');
          if (keyValueArray[1]) {
            switch (keyValueArray[0].replace('?', '')) {
              case 'store':
                setStore(String(keyValueArray[1]));
                break;
              case 'address':
                setAddress(keyValueArray[1]);
                break;
              case 'service':
                setService(keyValueArray[1]);
                break;
            }
          }
        });
      });
    getServices(setServices);
    getStoreTypes(setStoreTypes);

  }, []);

  const formikContext = useFormikContext();


  useEffect(() => {
    if (formikContext && name) {
      formikContext.setFieldValue(name, clicked);
    }
  }, [clicked]);


  useEffect(() => {
    setDisplayStores(stores?.filter(value => {
      if (store !== '' && value.storeType !== store) return false;

      // if(address !== '' && !value?.address?.toLowerCase().includes(address.toLowerCase())) return false;
      if (coordinates) {
        if (!value.lat || !value.long) return false;

        const storeDistance = haversineDistance(
          coordinates,
          {lat: value.lat, lng: value.long}
        );

        if (storeDistance > distance) return false;
      }

      if (service !== '' && !value.services.includes(service)) return false;

      return true;
    }));
  }, [store, address, service, coordinates, distance]);

  return (
    <div className="w-full">
      <div className={cls('lg:py-10 w-full', darkHeader ? ' bg-black' : ' bg-white')}>
        <FilterWrapper hasStoreList={hasStoreList} className="flex lg:flex-row flex-col lg:gap-10">

          <div className="flex lg:w-1/3 shrink-0">
            <div className="grow">
              <CityOrPostcodeField title="City or Postcode" className="w-full" invertColor={!darkHeader}
                                   onGeoCode={(coords) => setCoordinates(coords.lat ? coords : null)}/>
            </div>
            <div className="w-20 grow-0 shrink">
              <SelectField title="Distance" value={distance}
                           onChange={(e: ChangeEvent<HTMLSelectElement>) => setDistance(e.target.value)}
                           options={distanceOptions} invertColor={!darkHeader}/>
            </div>
          </div>
          <SelectField title="Store Type" value={store} clear={() => setStore('')}
                       onChange={(e: ChangeEvent<HTMLSelectElement>) => setStore(e.target.value)}
                       options={storeTypes} invertColor={!darkHeader}/>
          <SelectField title="Service" value={service} clear={() => setService('')}
                       onChange={(e: ChangeEvent<HTMLSelectElement>) => setService(e.target.value)}
                       options={services} invertColor={!darkHeader}/>
        </FilterWrapper>
      </div>
      <div className="lg:flex-row flex-col w-full flex">
        {hasStoreList ? <div className="flex flex-row w-full relative lg:h-screen ">
            <div
              className="w-full xl:w-3/4 ml-auto overflow-y-auto flex flex-col items-center max-h-screen scroll-smooth lg:pr-20 x-4 sm:pl-4 lg:py-16 lg:pl-8">
              {
                displayStores?.map(value => <StoreList key={value.id} value={value} hovering={hovering}/>)
              }
            </div>
          </div> :
          <div className="flex flex-row w-full relative ">
            <div
              className="w-full ml-auto overflow-y-auto flex flex-col items-center max-h-screen scroll-smooth">
              {
                displayStores?.map(value =>
                  <button type="button" key={value.id} className="text-left w-full" onClick={() => setSelectDealer(value)}><StoreList
                    hasStoreList={hasStoreList} value={value} hovering={hovering}/></button>
                )
              }
            </div>
          </div>
        }
        <div className="lg:order-last order-first w-full lg:h-screen h-slider">
          <StoresMap pins={displayStores} center={coordinates || undefined} setHovering={setHovering} apiKey={apiKey} setClicked={setClicked} radius={distance}/>
        </div>
      </div>

    </div>

  );
};
