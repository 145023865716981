export const sm = {
    min: '640px',
    max: '767px'
}

export const md = {
    min: '768px',
    max: '1023px'
}

export const lg = {
    min: '1024px',
    max: '1279px'
}

export const xl = {
    min: '1280px',
    max: '1535px'
}

export const two_xl = {
    min: '1536px'
}
