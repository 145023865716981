'use strict';
import axios from 'axios';
import {Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {SelectField} from './FormikFields/SelectField';
import {PrimaryButton} from './PrimaryButton';
import {SecondaryButton} from './SecondaryButton';

export interface Make {
    ClassName: string;
    Created: string;
    ID: string;
    LastEdited: string;
    Name: string;
    RecordClassName: string;
    Version: number;
}

export interface Model {
    ClassName: string;
    Created: string;
    ID: string;
    MakeID: string;
    LastEdited: string;
    Name: string;
    RecordClassName: string;
    Version: number;
}

export interface Series {
    ClassName: string;
    Created: string;
    ID: string;
    ModelID: string;
    LastEdited: string;
    Name: string;
    RecordClassName: string;
    Version: number;
}

export function getMakes(setAllMakes) {
    axios.get('/api/fitmy4x4/make')
        .then(function (response) {
            setAllMakes(response.data);
        });
}

export function getModels(make, setAllModels) {
    if (make) {
        axios.get('/api/fitmy4x4/model', {params: {makeID: make}})
            .then(function (response) {
                setAllModels(response.data);
            });
    } else {
        setAllModels([]);
    }
}

export function getSeries(make, model, setAllSeries) {
    if (make && model) {
        axios.get('/api/fitmy4x4/series', {params: {modelID: model}})
            .then(function (response) {
                setAllSeries(response.data);
            });
    } else {
        setAllSeries([]);
    }
}


export const fitMy4X4FormikProps = (redirect = false) => {
    return {
        initialValues: JSON.parse(document.querySelector('[data-react="vehicle"]')?.dataset?.props),
        // @ts-ignore
        onSubmit: (data) => {
            window.location.href = '/fit-my-4x4?' + (redirect ? 'redirect-back=1&' : '') + (data.make ? 'make=' + data.make + (data.model ? '&model=' + data.model + (data.series ? '&series=' + data.series : '') : '') : '');
        },
    }
};

export function FitMy4x4() {
    const [allMakes, setAllMakes] = useState<Make[] | []>([]);
    const [allModels, setAllModels] = useState<Model[] | []>([]);
    const [allSeries, setAllSeries] = useState<Series[] | []>([]);

    useEffect(() => {
        getMakes(setAllMakes);
    }, []);

    return (
        <>
            <Formik initialValues={fitMy4X4FormikProps().initialValues} onSubmit={fitMy4X4FormikProps().onSubmit}>
                {({handleSubmit, values}) => {
                    useEffect(() => {
                        getModels(values.make, setAllModels);
                    }, [values.make]);

                    useEffect(() => {
                        getSeries(values.make, values.model, setAllSeries);
                    }, [values.make, values.model]);

                    return (
                        <form className="flex lg:flex-row flex-col lg:space-x-5 lg:mt-10" onSubmit={handleSubmit}>
                            <SelectField title="Make" name="make" options={allMakes.map(make => ({value: make.ID, label: make.Name}))}/>
                            <SelectField title="Model" name="model" options={allModels.map(model => ({value: model.ID, label: model.Name}))}/>
                            <SelectField title="Series" name="series" options={allSeries.map(series => ({value: series.ID, label: series.Name}))}/>
                            {/*@ts-ignore*/}
                            <div className='flex lg:w-2/5 w-full shrink-0 lg:mt-0 mt-6'>
                                <SecondaryButton as="a" href="fit-my-4x4?clear=1" className="w-6 relative pl-8 pr-12" type="submit">
                                    <svg width="18" height="18" className="-ml-2 mt-1 fill-green" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.1666 2.47825L15.5216 0.833252L8.99992 7.35492L2.47825 0.833252L0.833252 2.47825L7.35492 8.99992L0.833252 15.5216L2.47825 17.1666L8.99992 10.6449L15.5216 17.1666L17.1666 15.5216L10.6449 8.99992L17.1666 2.47825Z"/>
                                    </svg>
                                </SecondaryButton>
                                <PrimaryButton text="Apply" type="submit"/>
                            </div>
                        </form>

                    );
                }}
            </Formik>
        </>
    );
}
