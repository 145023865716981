import React from 'react';
import cls from "classnames";

const PageWidthConstraint = ({children, className} : {children?: JSX.Element | JSX.Element[] | null, className?: string}) => (
  <div className={cls('mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8', className)}>
      {children}
  </div>
);

export default PageWidthConstraint;
