import {useFormikContext, useField} from "formik";
import React, {ChangeEvent, useEffect, useState} from 'react';
import {TextField as CustomTextField} from "../FormFields/TextField";


export const TextField = ({title, name, ...props}: { title: string; name: string; }) => {
    const {setFieldValue, errors,isSubmitting} = useFormikContext<any>();
    const [{value,onChange}] = useField(name);
    const [hasSubmitted, setHasSubmitted] = useState(false)
    useEffect(() => {
        if(isSubmitting){
            setHasSubmitted(isSubmitting)
        }
    },[isSubmitting])

    return (
        <>
            <CustomTextField title={title}
                             value={value}
                             onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)} {...props}/>
            {errors[name] && hasSubmitted ? <p className='text-red-450'>{errors[name]}</p> : null}
        </>
    )
}
