'use strict';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {Dialog, DialogContent} from './Dialog';


export function NonDealerPriceDialog() {
  return (
    <Dialog open={true}>
      <DialogContent>
        <div className="flex flex-col gap-6">
          <h2 className="text-3xl font-normal">Warning: Dealer Price not selected</h2>
          <p>You are currently not under the dealer pricing. Please switch back to dealer pricing or view current Order</p>
          <div className="flex gap-4 justify-end">
            <a href="/dealers/editOrder" className="bg-black text-white py-2 px-4 rounded-lg">View Order</a>
            <a href={'/dealers/setPriceType?priceCode=' + encodeURIComponent('Dealer Price')} className="bg-green py-2 px-4 rounded-lg">Switch to Dealer
              Pricing</a>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
